import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PublicGuard } from './guards/public.guard';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  {
    path: 'landing',
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingModule),
    canActivate: [PublicGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'verify/:token',
    loadChildren: () =>
      import('./pages/email-verify/email-verify.module').then(
        (m) => m.EmailVerifyModule
      ),
  },
  {
    path: 'invite-registration',
    loadChildren: () =>
      import('./pages/invite-registration/invite-registration.module').then(
        (m) => m.InviteRegistrationModule
      ),
  },
  {
    path: 'calculator', loadChildren: () => import('./pages/landing/pages/calculator/calculator.module').then(
      (m) => m.CalculatorModalModule
    )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
