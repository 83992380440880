import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "message-modal",
    templateUrl: "message.modal.html",
    styleUrls: ["message.modal.scss"]
})
export class MessageModal implements OnInit{
  public message!:string
    constructor (
        @Inject(MAT_DIALOG_DATA) private _data: {message:string}
    ){

    }

    ngOnInit(): void {
      this.message = this._data.message
    }


}
