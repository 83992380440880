import { Injectable } from "@angular/core";

declare let gtag: Function

@Injectable()
export class AnaliticsService  {
    constructor(

    ) { }

    public eventEmitter(
        eventName: string,
        parameters?: {
            parameterName: string,
            eventValue: string | number
        }[]) {
            let paramsObject = {};
            if (parameters && parameters?.length)
                parameters.forEach((el) => [
                    paramsObject = {
                        ...paramsObject,
                        [el.parameterName]: el.eventValue
                    }
                ])
                
            gtag('event', eventName, paramsObject);
    }
}