import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from './services/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{
  // public spinnerClass:string = 'loading__none'
  public spinnerClass:string = 'loading__none'

  constructor(private _loadingService:LoadingService,
    private translate: TranslateService){
    translate.setDefaultLang('arm');
  }
  ngOnInit(): void {
    // this.takeVariableValue(false)
    this._loadingService._loadingSubject$.subscribe({
      next:(value)=>{        
        this.spinnerClass = value
      }
    })
  }

  // public takeVariableValue (bool:boolean){
  //   this._loadingService.appChangeSpinnerClass(this.spinnerClass)
  // }


  title = 'Beon';
}
