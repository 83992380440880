import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  // private _loadingClass:string = 'loading__none'
  // private _loadingSubject$ = new BehaviorSubject<string>('loading__none')

  public _loadingSubject$ = new BehaviorSubject<string>('loading__none')
  public _modalSpinnerSubject$ = new BehaviorSubject<string>('loading__none')

  public showSpinner(ans?: true) {
    if (ans == true) {
      return 'loading__none'
    }
    else {
      return 'loading__body'
    }
  }
  public appChangeSpinnerClass(str:string){    
    this._loadingSubject$.next(str)
  }

  public modalChangeSpinnerClass(str:string){
    this._modalSpinnerSubject$.next(str)
  }

  // public appChangeSpinnerClass(str:string){
  //   if(str == 'loading__body'){
  //     this._loadingClass = "loading__none"
  //   }
  //   else{
  //     this._loadingClass = "loading__body"
  //   }
  //   this._loadingSubject$.next(this._loadingClass)
  // }
}
